import React from 'react';

const useNow = (refreshRate = 60000): number => {
  const [now, setNow] = React.useState<number>(Date.now());

  React.useEffect(() => {
    if (refreshRate) {
      const interval = setInterval(() => {
        setNow(Date.now());
      }, refreshRate);
      return () => clearInterval(interval);
    }
  }, [refreshRate]);
  return now;
};

export { useNow };
