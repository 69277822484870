import type { BroadcastFieldsFragment } from '@news/gql';
import { getHourMinuteTime } from '@news/lib';

import { Progress } from './progress';
import { Program, ProgramHeader, ProgramMoreInfo } from './styles';

function BroadcastItem({
  broadcast,
  alreadyBeenBroadcast,
  live,
  active,
  handleSetActive,
}: {
  broadcast: BroadcastFieldsFragment;
  alreadyBeenBroadcast: boolean;
  live: boolean;
  active: boolean;
  handleSetActive: (id: string) => void;
}) {
  const start = getHourMinuteTime(new Date(broadcast.start));

  const synopsis =
    (broadcast.synopsis?.medium ||
      broadcast.synopsis?.brief ||
      broadcast.synopsis?.short ||
      broadcast.synopsis?.long) ??
    '';

  return (
    <Program $live={live} onClick={() => handleSetActive(broadcast.id)} data-testid="program">
      <article>
        <ProgramHeader $active={active} $live={live} $alreadyBeenBroadcast={alreadyBeenBroadcast}>
          <span>{start}</span>
          <h3>{broadcast.title}</h3>
          {live && <Progress start={broadcast.start} end={broadcast.end} />}
        </ProgramHeader>
        {active && (
          <ProgramMoreInfo $alreadyBeenBroadcast={alreadyBeenBroadcast}>
            <p>{synopsis}</p>
          </ProgramMoreInfo>
        )}
      </article>
    </Program>
  );
}

export { BroadcastItem };
