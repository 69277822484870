import React, { useEffect, useState } from 'react';

import { progressCounter } from '@news/lib';

import { useNow } from 'hooks/useNow';

import { ProgressBarContainer, ProgressPercentage } from './styles';

const Progress = ({ start, end }: { start: string; end: string }) => {
  const now = useNow(60000);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const p = progressCounter({ now, start, end });
    if (p > percentage) {
      setPercentage(p);
    }
  }, [now, percentage, start, end]);

  return (
    <ProgressBarContainer>
      <ProgressPercentage $percentage={percentage} />
    </ProgressBarContainer>
  );
};

export { Progress };
