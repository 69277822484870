import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import type { BroadcastFieldsFragment } from '@news/gql';
import { isBroadcastLive } from '@news/lib';

import { KeyboardLeftArrowIcon } from 'components/icons';
import { isMobile } from 'lib/helpers';

import { BroadcastStatus, setProgramStatus } from '../checkProgramStatus';
import type { Day } from '../index';
import { ChannelHeader, ImageContainer } from '../styles';
import { BroadcastItem } from './broadcast';
import { BroadcastList as List, ShowAllDayButton } from './styles';

type ScrollRefType = {
  current: HTMLSpanElement | null;
};

function BroadcastList({
  broadcasts,
  channelId,
  channelName,
  scrollRef,
  checkWhatsLive,
  day,
  logotype,
}: {
  broadcasts: BroadcastFieldsFragment[];
  channelId: string;
  channelName: string;
  checkWhatsLive: boolean;
  logotype: string;
  day?: Day;
  scrollRef?: ScrollRefType;
}) {
  const [activeList, setActiveList] = useState<string[]>([]);
  const [liveIndex, setLiveIndex] = useState<number>(
    checkWhatsLive ? broadcasts.findIndex((broadcast) => isBroadcastLive(broadcast.start, broadcast.end)) : -1
  );
  const [showAll, setShowAll] = useState<boolean>(false);
  const previousShowAll = usePrevious(showAll);
  const oneMinute = 60000;

  useEffect(() => {
    if (checkWhatsLive) {
      const timer = setInterval(() => {
        setLiveIndex(broadcasts.findIndex((broadcast) => isBroadcastLive(broadcast.start, broadcast.end)));
      }, oneMinute);
      setLiveIndex(broadcasts.findIndex((broadcast) => isBroadcastLive(broadcast.start, broadcast.end)));
      return () => clearInterval(timer);
    }
  }, [broadcasts, checkWhatsLive]);

  useEffect(() => {
    if (isMobile() && scrollRef?.current && (previousShowAll || showAll)) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [previousShowAll, showAll, scrollRef]);

  useEffect(() => {
    setShowAll(false);
  }, [day]);

  const handleSetActive = (id: string): void => {
    const index = activeList.indexOf(id);
    if (index > -1) {
      const arr = [...activeList];
      arr.splice(index, 1);
      setActiveList(arr);
    } else {
      const arr = [...activeList, id];
      setActiveList(arr);
    }
  };

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const programs = broadcasts.map((broadcast, index) => {
    return {
      ...broadcast,
      status: setProgramStatus({ day: day?.text, index, liveIndex }),
    };
  });
  const programsToDisplay = showAll ? programs : programs.splice(liveIndex > -1 ? liveIndex : 0, 10);
  return (
    <>
      <ChannelHeader onClick={handleShowAll}>
        <ImageContainer>
          <img alt={channelName} src={logotype} />
        </ImageContainer>
      </ChannelHeader>
      <List>
        {programsToDisplay.map((broadcast) => {
          const active = !!activeList.includes(broadcast.id);
          return (
            <BroadcastItem
              key={`${channelId}-${broadcast.id}`}
              broadcast={broadcast}
              alreadyBeenBroadcast={broadcast.status === BroadcastStatus.PAST_TENSE}
              live={checkWhatsLive && broadcast.status === BroadcastStatus.LIVE}
              active={active}
              handleSetActive={handleSetActive}
            />
          );
        })}
        <ShowAllDayButton type="button" $showAll={showAll} onClick={handleShowAll}>
          Se hela dagen <KeyboardLeftArrowIcon />
        </ShowAllDayButton>
      </List>
    </>
  );
}

export { BroadcastList };
